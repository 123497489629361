import React from 'react'
import { graphql, useStaticQuery  } from 'gatsby'


export default function DownloadWaterTestView() {
    

    const data = useStaticQuery(graphql`
    query MyQuery {
        allTestresultsJson(sort: {fields: cDate, order: DESC}) {
          nodes {
            id
            Name
            cDate
            ext
          }
        }
        allFile {
          nodes {
            name
            publicURL
          }
        }
      }    
        `)
              
      
      const testresultsdb = data.allTestresultsJson.nodes
      const testresultsfiles = data.allFile.nodes
      const files = [];
      
      testresultsdb.forEach(elementdb => {
        testresultsfiles.forEach(element => {
            if(element.name === elementdb.Name){
                elementdb['publicURL'] = (element.publicURL)
            }
            
        })
        files.push(elementdb)
      });

      function pad(str) {
        if (str.length === 2) return str;
        return pad('0' + str);
      }

      function renderDato(str){
        return str.split('-').map(function (el) {
          if (el.length < 2) return pad(el);
          return el;
        }).join('-');
      }
    return (
        <div key="ContactData">
        <div className="">
            <div className="">
                <h4>Testresultater</h4>
                <p></p>
                <table>
                    <tbody>                   
                {files.map(testresult => (                

                    <tr key={testresult.id}>
                        <th>{renderDato(testresult.cDate)}</th>
                        <th style={{paddingLeft:'15px'}}><a href={testresult.publicURL} target="_blank" rel="noreferrer">{testresult.Name+ testresult.ext}</a></th>
                    </tr>
                ))}
                    </tbody>
                </table>     
            </div>
    </div>
</div>
            )
}

