import React from 'react'
import { graphql, useStaticQuery  } from 'gatsby'
import Layout from '../components/Layout'
import DownloadWaterTestView from '../components/DownloadWaterTestView'

export default function Waterquality() {

    const data = useStaticQuery(graphql`
    query WaterQuality {
        allWaterqualityJson {
          nodes {
            max
            min
            id
            name
            unit
            value
            testDato
          }
        }
      }
      
      
      
      `)
const waterquality = data.allWaterqualityJson.nodes


    return (
        <Layout>
            <div className="containerQuality" style={{gridTemplateColumns: '2fr 1fr'}} >
                
                    
                    <div className="card" style={{overflow: 'auto'}} key="table"  >
                        <div className="card-content">
                            <div className="content">
                                <p className="card-header-title">
                                    Aktuelle værdier
                                </p>
                                <table className='table is-striped'>
                                    <thead>
                                        <tr>
                                            <th>Inhold</th>
                                            <th>Min</th>
                                            <th>Max</th>
                                            <th>Værdi</th>
                                            <th>Enhed</th>
                                        </tr> 
                                    </thead>
                                    <tbody>
                                    {waterquality.map(content => (
                                    <tr key={content.id}>
                                        <th>{content.name}</th>
                                        <th>{content.min}</th>
                                        <th>{content.max}</th>
                                        <th>{content.value}</th>
                                        <th>{content.unit}</th>
                                    </tr>

                                    ))}
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="card" key="qualityDownload"  >
                        <div className="card-content">
                            <div className="content">
                                <DownloadWaterTestView/>

                            </div>
                        </div>
                    </div>
            
                
                
                
            </div>
        </Layout>


    )
}
